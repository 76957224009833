<template>
<div class=" mt-5 " >
  <v-data-table
    :headers="headers"
    :items="fieldGroups"
    class="elevation-1"
    v-sortable-data-table
    @sorted="saveOrder"
    disable-sort
    item-key="id"
    items-per-page=15
    :footer-props="{
      itemsPerPageOptions:[15,30,50,-1]
    }"
  >
    <template v-slot:top>
      <v-toolbar flat >
        <v-toolbar-title>Column Groups </v-toolbar-title>
        <v-divider class="mx-4" inset vertical ></v-divider>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="600px" >
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on"  >
              New Group
            </v-btn>
          </template>
           <v-form ref="form" v-model="valid" lazy-validation >
          <v-card>
            <v-card-title class="accent">
              <span  class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col  >
                    <v-text-field :rules="[ v => !!v ||'Name is required!']"  v-model="selectedFieldGroup.name" label="Group Name" ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4"  >
                    <v-select v-model="selectedFieldGroup.groupType" :items="groupTypes" item-text="id" item-value="id" label="Type"    ></v-select>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close" >
                Cancel
              </v-btn>
              <v-btn :disabled="!valid" color="blue darken-1" text @click="save" >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
           </v-form>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="accent">
              <span  class="text-h5">Delete Group </span>
            </v-card-title>
            <v-card-text class="body-1 mt-6 ">Are you sure you want to delete <b>{{selectedFieldGroup.name}}</b>?</v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary darken-1" text @click="closeDelete">Cancel</v-btn>
              <v-btn color="red darken-1" text @click="deleteItemConfirm">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-tooltip top color="orange">
        <template v-slot:activator="{ on }">
              <v-icon v-on="on"  class="mr-2" style="cursor:move">mdi-drag-variant</v-icon>
        </template>
        <span >Drag {{item.name}} to change the order</span>
      </v-tooltip>
      <v-tooltip top color="primary">
        <template v-slot:activator="{ on }">
            <v-btn
              icon

              color="primary"
              @click="editItem(item)"
              v-on="on"
            >
              <v-icon class="mr-2">mdi-file-edit-outline</v-icon>
            </v-btn>
        </template>
        <span>Edit {{item.name}}</span>
      </v-tooltip>
      <v-tooltip top color="orange">
        <template v-slot:activator="{ on }">
            <v-btn
              icon
              large
              color="orange"
              @click="deleteItem(item)"
              v-on="on"
            >
              <v-icon class="mr-2">mdi-delete-forever</v-icon>
            </v-btn>
        </template>
        <span>Delete {{item.name}}</span>
      </v-tooltip>

    </template>
  </v-data-table>
</div>
</template>

<script>
  import { getFieldGroups, addFieldGroup, deleteFieldGroup, editFieldGroup, editFieldGroupOrder } from '@/api/settings.js'
  import Sortable from 'sortablejs';

  export default {
    data: () => ({
      valid: true,
      dialog: false,
      dialogDelete: false,
      headers: [
        { text: 'NAME', align: 'start', value: 'name' ,class:"accent h2"},
        { text: 'TYPE', align: 'start', value: 'groupType' ,class:"accent h2"},
        { text: 'ACTIONS', align: 'end', value: 'actions', sortable: false ,class:"accent"},
      ],
      groupTypes: [
        {id: 'ID'},
        {id: 'CASTING'},
        {id: 'CASTING_PROPERTIES'},
        {id: 'HEAT TREATMENT'},
        {id: 'COMPOSITION_AT'},
        {id: 'COMPOSITION_WT'},
        {id: 'ALUMINIUM'},
        {id: 'ALLOY_DESIGN'}
      ],
      fieldGroups: [],
      editedIndex: -1,
      selectedFieldGroup: {
        id: -1,
        name: '',
        groupType: '',
        order: 0,
      },
      defaultItem: {
        id: -1,
        name: '',
        groupType: '',
        order: 0

      },
    }),

    computed: {
      formTitle () {
        return this.selectedFieldGroup.id === -1 ? 'New Group' : 'Edit Group'
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    created () {
      this.initialize()
    },
    directives: {
      sortableDataTable: {
        bind (el, binding, vnode) {
          const options = {
            animation: 150,
            onUpdate: function (event) {
              vnode.child.$emit('sorted', event)
            }
          }
          Sortable.create(el.getElementsByTagName('tbody')[0], options)
        }
      }
    },
    methods: {
      saveOrder (event) {
        const movedItem = this.fieldGroups.splice(event.oldIndex, 1)[0];
        this.fieldGroups.splice(event.newIndex, 0, movedItem);
        const payload = this.fieldGroups.map((item,idx) => { return { id:item.id, order:idx }})
        editFieldGroupOrder(payload, 'The order of groups has changed successfully')
      },
      loadFieldGroups(){
        const _this = this
        getFieldGroups(function(res){
          _this.fieldGroups = res
        })
      },
      initialize () {
        this.loadFieldGroups()
      },

      editItem (item) {
        this.selectedFieldGroup =  Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.selectedFieldGroup =  Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
        const _this = this
        deleteFieldGroup(this.selectedFieldGroup.id,function(res){
          console.log(res)
          _this.loadFieldGroups()
        }, 'Group ' + _this.selectedFieldGroup.name + " removed successfully" )
        this.closeDelete()
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.selectedFieldGroup = Object.assign({}, this.defaultItem)
          //this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.selectedFieldGroup = Object.assign({}, this.defaultItem)
          //this.editedIndex = -1
        })
      },

      save () {
        var isValidated = this.$refs.form.validate()
        if (isValidated){
          if (this.selectedFieldGroup.id > -1) {
            const _this = this
            editFieldGroup(this.selectedFieldGroup.id, this.selectedFieldGroup, function(res){
              console.log(res)
              _this.loadFieldGroups()
            }, 'Group ' + _this.selectedFieldGroup.name +  ' updated successfully'
            )
          } else {
            const _this = this
            this.selectedFieldGroup.order = this.fieldGroups.length
            addFieldGroup(this.selectedFieldGroup, function(res){
              console.log(res)
              _this.loadFieldGroups()
            }, 'Group ' + _this.selectedFieldGroup.name +  ' added successfully'
            )
          }
          this.close()
        }
      },
    },
  }
</script>